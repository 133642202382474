@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    list-style: none;
    text-decoration: none;
}
body{
    background: #E5E5E5;
    font-family: 'Oxanium', sans-serif;
    color: #000b17;
}


